<template>
  <div class="jd-sure-order-wrap">
    <navbarTools :leftArrow="true" :fixed="true"></navbarTools>
    <div class="wapper bgc-F7F7F7 jd-sure-order-wrap">
      <div class="ml-15 mr-15">
        <van-notice-bar
          color="#FAAD14"
          background="rgba(250, 173, 20,0.09)"
          mode="closeable"
          @close="closeFn"
        >
          注意：提交订单后30分钟内完成支付，否则订单会被自动取消
        </van-notice-bar>
      </div>
      <div v-if="!showNotice" class="h-10"></div>
      <order
        :address-detail="defaultAddress"
        :goods-data="goodsDeail"
        :msg-title-list="msgTitleList"
        :msg-value-list="msgValueList"
        ref="order"
        :goodsNum="goodsNum"
      ></order>
      <!-- 底部按钮 -->
      <div class="btn-submit-box iphonex-bottom jd-btn-fixed-bottom" v-show="btnShow">
        <div class="fs14 c-333 mr30 flex text-left">
          <div>应付款：</div>
          <div class="c-FE6507 fs12 flex text-left">
            <div class="fs16 mr1">{{ totalMoney }}</div>
            <div>工钱币</div>
          </div>
        </div>
        <div
          class="bgc-FE6507 line-height-40 radius3 c-fff fs16 pl23 pr23"
          @click="submitOrderFn"
          :class="{ 'btn-no-submit-order': !ifCheck }"
        >
          提交订单
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbarTools from "@/components/navbarTools";
import order from "@/components/shop/order";
import { config } from "@/config";
import {
  defineComponent,
  toRefs,
  reactive,
  getCurrentInstance,
  ref,
  onMounted,
} from "vue";
import {
  jdGoodsDetail,
  getDefaultAddress,
  jdGoodsFreight,
  selfGoodsDetail,
  orderCommit,
  checkAddressValid,
} from "@/api/shop";
import { useRouter, useRoute } from "vue-router";
import { Toast } from "vant";
export default defineComponent({
  components: {
    navbarTools,
    order,
  },
  created() {
    let app = getCurrentInstance();
    app.proxy.jdGoodsDetailFn();
    app.proxy.getDefaultAddressFn();
  },
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    let order = ref(null);
    const state = reactive({
      btnShow: true,
      showNotice: true,
      goodsId: $route.query.goodsId,
      goodsNum: $route.query.goodsNum,
      defaultAddress: {},
      goodsDeail: null,
      totalMoney: 0,
      addressId: null,
      ifCheck: true,
      remark: null,
      goodsChannel: $route.query.goodsChannel,
      msgTitleList: [
        { title: "商品总金额", value: "money", class: "c-FE6507" },
        { title: "订单运费", value: "freight" },
        { title: "配送信息", value: "delivery" },
        {
          title: "我的留言",
          value: "message",
          placeholder: "选填，对本次交易的说明",
          type: "textarea",
          maxlength: 30,
        },
      ],
      msgValueList: {
        money: 0,
        freight: "包邮",
        delivery: "京东配送",
      },
    });
    const closeFn = () => {
      state.showNotice = false;
    };

    const jdGoodsDetailFn = async () => {
      const loading = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
        overlayClass: "loadingOverlay",
      });
      let rs = null;
      if (state.goodsChannel == "0") {
        rs = await jdGoodsDetail({ goodsId: state.goodsId });
      } else {
        rs = await selfGoodsDetail({ goodsId: state.goodsId });
      }
      state.goodsDeail = rs.data;
      state.totalMoney = (parseInt(rs.data.coinPrice * 100) * state.goodsNum) / 100;
      state.msgValueList.money = `${
        (parseInt(rs.data.coinPrice * 100) * state.goodsNum) / 100
      }工钱币`;
      if (state.goodsChannel == "1") {
        state.msgValueList.delivery = "自营配送";
      } else if (state.goodsChannel == "0") {
        jdGoodsFreightFn();
      }
      loading.clear();
    };
    const getDefaultAddressFn = async () => {
      let address = sessionStorage.getItem("jdChooseAddress");
      if (address) {
        let obj = JSON.parse(address);
        let rs = await checkAddressValid({ addressId: obj.userAddressId });
        if (rs.code === 0) {
          if (rs.data.isValid) {
            state.defaultAddress = JSON.parse(address);
            state.addressId = JSON.parse(address).userAddressId;
          } else {
            let rs = await getDefaultAddress();
            state.defaultAddress = rs.data ?? {};
            state.addressId = rs.data?.userAddressId ?? null;
          }
        }
      } else {
        let rs = await getDefaultAddress();
        state.defaultAddress = rs.data ?? {};
        state.addressId = rs.data?.userAddressId ?? null;
      }
      // if (address) {
      //   state.defaultAddress = JSON.parse(address);
      //   state.addressId = JSON.parse(address).userAddressId
      // } else {
      //   let rs = await getDefaultAddress();
      //   state.defaultAddress = rs.data ?? {}
      //   state.addressId = rs.data?.userAddressId ?? null
      // }
    };
    const jdGoodsFreightFn = async () => {
      if (state.addressId) {
        let obj = {
          addressId: state.addressId,
          goodsIdList: [
            {
              goodsChannel: 0,
              goodsId: state.goodsId,
              num: state.goodsNum,
            },
          ],
        };
        let rs = await jdGoodsFreight(obj);
        if (rs.code === 0) {
          state.totalMoney =
            (parseInt(state.totalMoney * 100) + parseInt(rs.data.freight * 100)) / 100;
          if (rs.data.freight > 0) {
            state.msgValueList.freight = `${rs.data.freight}工钱币`;
            state.msgTitleList[1].class = "c-FE6507";
          }
        }
      }
    };
    const submitOrderFn = async () => {
      if (state.ifCheck) {
        state.ifCheck = false;
          setTimeout(() => {
            state.ifCheck = true;
          }, 1500);
        if (state.addressId) {
          let obj = {
            remark: order.value.state.ruleForm.message,
            addressId: state.addressId,
            goodsIdList: [
              {
                goodsChannel: state.goodsChannel,
                goodsId: state.goodsId,
                num: state.goodsNum,
              },
            ],
          };
          let rs = await orderCommit(obj);
        
          if (rs?.code === 0) {
            $router.push({
              path: "/jdshop/checkstand",
              query: {
                orderSn: rs.data.orderSn,
                goodsChannel: state.goodsChannel,
              },
            });
          }
        } else {
          Toast("请选择收货地址");
        }
      }
    };

    onMounted(() => {
      let h = document.documentElement.clientHeight;
      window.onresize = () => {
        let height = document.documentElement.clientHeight;
        if (h == height) {
          state.btnShow = true;
        } else {
          state.btnShow = false;
        }
      };
    });
    return {
      ...toRefs(state),
      closeFn,
      order,
      jdGoodsDetailFn,
      getDefaultAddressFn,
      submitOrderFn,
    };
  },
});
</script>
<style lang="scss">
.jd-sure-order-wrap {
  .van-notice-bar {
    .van-notice-bar__right-icon {
      color: rgba($color: #000000, $alpha: 0.29);
      font-size: 14px;
      padding-top: 5px;
    }
  }
}
</style>
<style lang="scss" scoped>
.jd-sure-order-wrap {
  min-height: 100vh;
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
      .van-nav-bar__right .van-nav-bar__text {
        color: #333;
      }
    }
  }
  .van-notice-bar {
    font-size: 12px;
    padding: 0 15px;
    height: 28px;
  }
  .btn-submit-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-no-submit-order {
      background: rgba(0, 0, 0, 0.15);
    }
  }
}
</style>

<template>
  <div class="jd-order">
    <!-- 收货地址 -->
    <div class="bgc-fff ml-15 mr-15"
         v-if="showAddress">
      <van-cell center
                is-link
                class="flex pr15 pl15 pt20 pb20"
                title-class="c-333 fs16"
                label-class="c-999 fs12 mt10"
                @click="addressFn">
        <template #title>
          <div class="w-300">
            <div class="font-weight  on-line">
              <div v-if="addressDetail.name">
                <span>{{addressDetail.name}}</span>
                <span class="ml20">{{addressDetail.mobile}}</span>
              </div>
              <div v-else>收货地址</div>
            </div>
          </div>
        </template>
        <template #label>
          <div class="w-300">
            <div class="on-line">
              {{
                addressDetail.address
                  ? addressDetail.provinceName +
                    addressDetail.cityName +
                    addressDetail.areaName +
                     addressDetail.townName +
                    addressDetail.address
                  : "请添加您的收货地址"
              }}
            </div>
          </div>
        </template>
        <template #right-icon>
          <span class="icon iconfont iconiconfontjiantou2 fs18 c-000"></span>
        </template>
      </van-cell>
    </div>
    <!-- 商品展示 -->
    <div class="mt10 bgc-fff pt20 pl15 pr15 ml-15 mr-15 pb10">
      <goods-item size="small"
                  :goodsDetail="goodsData"
                  :goodsNum="goodsNum"
                  type="order"
                  v-if="goodsData?.goodsId"></goods-item>
      <div class="mt20 order-border-top pt10">
        <div v-for="(item, index) in msgTitleList"
             :key="index">
          <van-field v-if="item.type == 'input'"
                     :label="item.title + '：'"
                     v-model="ruleForm[item.value]"
                     :placeholder="item.placeholder"
                     :border="false"
                     class="order-field"
                     label-class="w-70" />
          <van-field v-if="item.type == 'textarea'"
                     :label="item.title + '：'"
                     v-model="ruleForm[item.value]"
                     :placeholder="item.placeholder"
                     :border="false"
                     class="order-field"
                     autosize
                     type="textarea"
                     :maxlength="item.maxlength" />
          <van-cell v-else
                    :title="item.title + '：'"
                    title-class="c-333 fs12 h40"
                    value-class="c-333 fs12 h40"
                    class="pr0 pl0 pt10 pb10"
                    style="height:40px">
            <template #default>
              <div :class="item.class">
                {{ msgValueList[item.value] }}
              </div>
            </template>
          </van-cell>
        </div>
        <slot name="btn"></slot>
      </div>
    </div>
  </div>
</template>
<script>
  import goodsItem from "./goodsItem";
  import { defineComponent, toRefs, reactive, getCurrentInstance } from 'vue'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    props: {
      addressDetail: Object,
      goodsData: Object,
      msgTitleList: Array,
      msgValueList: Object,
      goodsNum: String,
      showAddress: {
        type: Boolean,
        default: true,
      },
    },
    components: { goodsItem },
    setup(props, { expose }) {
      const $router = useRouter();
      const state = reactive({
        ruleForm: {
          message: ''
        }
      });
      const addressFn = () => {
        $router.push({
          path: '/address/addressList'
        })
      };
      expose({
        state
      })
      return {
        addressFn,
        ...toRefs(state)
      }
    }
  });
</script>
<style lang="scss" scoped>
  .jd-order {
    .order-border-top {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      ::v-deep(.h40) {
        height: 40px;
      }
    }
    .order-field {
      padding: 8px 0;
      font-size: 12px;
      line-height: 16px;
    }
  }
</style>